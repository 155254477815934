import React from 'react';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Landingpage01 } from './ui-components';

function App() {
  return <Landingpage01 />;
}

export default withAuthenticator(App);
